html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center {
  text-align: center;
}

.fade {
  opacity: 0;
  visibility: hidden;
}

.fade.fade--in {
  opacity: 1;
  transition: opacity .5s, visibility .5s;
  visibility: visible;
}

.fade.fade--out {
  opacity: 0;
  transition: opacity .5s, visibility .5s;
  visibility: hidden;
}

.fill {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: 3s infinite spin linear;
}
